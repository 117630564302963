<template>
  <div>
    <!-- New & Edit Dialog Box -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="secondary white--text headline font-weight-medium">
          Country Details
          <v-spacer />
          <v-btn
            flat
            icon
            color="white"
            @click.native="close"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="editedItem.d_abbreviation"
                  label="Abbreviation"
                />
              </v-flex>
              <v-flex xs12>
                <v-switch
                  v-model="editedItem.f_status"
                  label="Status"
                  color="secondary"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            round
            color="primary"
            @click.native="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog Box -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Delete this record?</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                "{{ editedItem.d_abbreviation }}" will be deleted from the system!
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color=""
            flat
            @click.native="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click.native="deleteItem"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Toolbar-->
    <v-toolbar
      flat
      color="white"
    >
      <!--  Search Box -->
      <v-form
        @submit.prevent="runSearch"
      >
        <v-text-field
          v-model="search"
          label="Search"
          prepend-icon="fal fa-search"
          :append-icon="search ? 'fal fa-times-circle' : undefined"
          single-line
          hide-details
          @click:prepend="runSearch"
          @click:append="clearSearch"
        />
      </v-form>

      <v-spacer />

      <v-btn
        color="green lighten-1"
        round
        dark
        class="mb-2"
        @click="newItem()"
      >
        New
      </v-btn>
    </v-toolbar>

    <!--List-->
    <v-layout
      row
      wrap
    >
      <v-flex
        d-flex
        xs12
        class="pt-0"
      >
        <v-card
          flat
        >
          <v-list
            two-line
            class="pt-0"
          >
            <template v-for="(item, i) in countriesStore.countries">
              <v-list-item
                :key="i"
                @click="editItem(item)"
              >
                <v-list-item-content>
                  <v-list-item-title class="text--primary">
                    {{ item.id }}
                  </v-list-item-title>
                  <v-list-item-sub-title>{{ item.d_abbreviation }}</v-list-item-sub-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Countries',
  computed: {
    ...mapState({
      countriesStore: (state) => state.countriesStore,
    }),
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      search: '',
      editFlag: -1,
      editedItem: {
        id: '',
        d_abbreviation: '',
      },
      defaultItem: {
        id: '',
        d_abbreviation: '',
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      /* Store authorized user in store */
      this.$store.dispatch('countriesStore/fetch');
    },

    newItem() {
    	this.editedItem.f_status = 1;
    	this.dialog = true;
    },

    editItem(item) {
      this.editFlag = 1;
      this.editedItem = { ...item };
      this.dialog = true;
    },

    save() {
	    this.$store.dispatch('countriesStore/readProfile', this.editedItem).then(() => {

	    });
    },

    openDeleteDialog(item) {
      this.editedItem = { ...item };
      this.deleteDialog = true;
    },

    close() {
      this.dialog = false;
      this.deleteDialog = false;
      setTimeout(() => {
        this.editedItem = { ...this.defaultItem };
        this.editFlag = -1;
      }, 300);
    },

    runSearch() {
      if (this.search) {
        const searchQuery = {
          search: this.search,
        };
        this.$store.dispatch('countriesStore/query', searchQuery);
      } else {
        this.$store.dispatch('countriesStore/fetch');
      }
    },

    clearSearch() {
      this.search = '';
      this.initialize();
    },
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
